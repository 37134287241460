import App from './App.vue'
import router from './router'
import store from './store'
import Vue from 'vue';
import ElementUI from 'element-ui';
import axios from 'axios'
import 'element-ui/lib/theme-chalk/index.css';

Vue.config.productionTip = false
Vue.use(ElementUI);
new Vue({
  
  el: '#app',
  router,
  store,
  axios,
  render: h => h(App)
}).$mount('#app')
