import { render, staticRenderFns } from "./WindowsView.vue?vue&type=template&id=6184f32c&scoped=true&"
import script from "./WindowsView.vue?vue&type=script&lang=js&"
export * from "./WindowsView.vue?vue&type=script&lang=js&"
import style0 from "./WindowsView.vue?vue&type=style&index=0&id=6184f32c&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6184f32c",
  null
  
)

export default component.exports