<template>
  <div class="about">
    <h1>账号注册须知</h1>

    <div>
    

      <div class="windows_img">
        <img src=".././img/register/register_1.png" alt="" class="img_2">
        <img src=".././img/windows/4.png" alt="" class="img_1">
        <img src=".././img/register/register_2.png" alt="" class="img_3">
        <img src=".././img/windows/4.png" alt="" class="img_1">
        <img src=".././img/register/register_3.png" alt="" class="img_4">
        <img src=".././img/windows/4.png" alt="" class="img_1">
        <img src=".././img/register/register_4.png" alt="" class="img_5">
     
      </div>
      <div class="text_all">
        <div class="text">1、安装成功后，可以在屏幕桌面上找到《医学文献知识发现服务》软件启动图标；</div>
        <div class="text">2、打开软件后，点击右上角“登录”按钮调整至登录页面；点击下方“注册账号”填写个人信息与账号密码；</div>
        <div class="text">3、注册成功后右上角会显示“注册成功"，点击正下方的登录按钮，使用刚才注册的账号密码进行登录；</div>

          <div class="text_a">双击打开软件。</div>
          <img src=".././img/windows/5.png" alt="" class="img_11">
        </div>
        
      </div>
   

     
    
          <h1 class="download">
            <button><a href="/">返回下载页面</a></button>
            
          </h1>
     
  </div>
</template>
<script>
import axios from 'axios'
// 产品名称
const productName = typeof window.PRODUCT_NAME !== 'undefined' ? window.PRODUCT_NAME : '智慧导读'
const baseUri = typeof window.API_BASE_URL !== 'undefined' ? window.API_BASE_URL : '/api'
const flavor = typeof window.PRODUCT_FLAVOR !== 'undefined' ? window.PRODUCT_FLAVOR : 'public'

const title = '《' + productName + '》下载'

if (window.BELONG) {
  document.title = window.BELONG + title
} else {
  document.title = title
}
export default {
  data(){
    return{
        buttons:[]
    }
  },

    mounted () {
    // var abc = document.documentElement.clientHeight;

    // var element = document.getElementById('app');
    // element.style.height = abc+'px';
    //     console.log(element);
    const now = new Date()
    // uri参数缓存
    const version = new Date().setHours(now.getHours(), now.getMinutes(), 0, 0).toString()
    axios.get(baseUri + '/versions/sorted?_=' + version + '&flavor=' + flavor).then((res) => {
      // 如果下载地址为外链
      const homeUri = window['download-origin'] || ''
      // 各平台下载链接
      const caches = {}
      // 按钮列表
      const buttons = []
 
      res.data.items.forEach(function (version) {
        const flavor = version.flavor?.name || 'default'
        const baseUri = homeUri + '/download/flavor/' + flavor + '/' + version.name

        // 各版本下的构建
        version.assets.forEach(function (asset) {
          const platform = asset.platform
          const filetype = asset.filetype

          switch (filetype) {

            case '.exe': break
            default: return
          }

          if (caches.hasOwnProperty(platform)) {
            return
          }

          let name
          let version
          switch (platform) {
            case 'windows_64': name = '下载Windows版本';version=true; break
          }

          const button = { href: baseUri + '/' + platform + '/' + asset.name, name: name,version:version }
          console.log(button);
          caches[platform] = button
          buttons.push(button)
        })
      })

      this.buttons = buttons
    })
  }
}
</script>
<style lang="scss" scoped>
.img_2{
  width:15vw;
  position: relative;
  top: -65px;
}
.img_3{
  width:15vw;
}
.img_4{
  width:15vw;
  position: relative;
  top: -45px;
}
.img_5{
  width:25vw;
  position: relative;
  top: -60px;
}
.about{
  padding-top:3vw ;
}
.windows_img{
  margin-top: 1%;
img{
margin-left: 2%;
}

.img_1{
  padding-bottom:8vw ;
  width: 5vw;
}
}
.img_11{
  position: relative;
  left:580px;
  top: -220px;
}
h1,button{
  margin: 0;
  display: flex;  
  justify-content: center;
}


  button{
    margin-top: 40px;
    width: 200px;
    font-size: 20px;
  }

.img_text{
  width: 150px;
  position: relative;
  left: 80vw;
  top: -5vw;
  font-size: 0.9vw;
}

.text_all{
margin-left: 30%;
}
.text{
  margin-top:50px ;
}
.text_a{
  position: relative;
  width: 150px;
  left: 660px;
  top: -162px;

}
.download{
  display: flex;
  justify-content: space-evenly;
  a{
      text-decoration:none;
      line-height: 35px;
      color: black;
  }
}
</style>
