import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import Windows from '../views/WindowsView.vue'
import MacOs from'../views/MacOsView.vue'
import Register from'../views/Register.vue'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/Windows',

    component:Windows
  },
  {
    path: '/Register',

    component:Register
  },
  {
    path: '/MacOs',

    component:MacOs
  }
]

const router = new VueRouter({
  mode: process.env.ROUTE_MODE || 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
