<template>
  <div>
    
    <router-view></router-view>
  </div>
</template>

<script>
export default {

}
</script>

<style>
html{
  height: 100%;
}
body{

 background-image: url('./img/bg.png');  
            background-repeat: no-repeat;  
            background-position: center;  
            background-size: cover;  
            margin: 0;  
            padding: 0;  
            width: 100%;  
            
    
}
  .el-carousel__button{
    background-color:black !important;
  }
</style>