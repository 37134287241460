<template>
  <div id="app">
    <div class="download">

         <div class="download_text">
        <img src=".././img/logo.png" alt="">
        
        <span class="title">《{{ productName }}》</span><br>

        <div class="top">
          <div class="jj">
          传统文献阅读是基于纸张、电子版平面的文本逐行顺序阅读
          </div>
          <div class="jj jjred">
            《智慧导读》是基于概念语词及其语义关联的超文本可视化导读
          </div>
          <div class="jj">
            省时、省力、高效，助力文献阅读中的启发式创新思维
          </div>
        </div>
      </div>

    </div >
      
    <div class="text">
         <div class="block">
            <span class="demonstration"></span>
            <el-carousel trigger="click" height="20vw"  arrow="never">
              <el-carousel-item v-for="item in items" :key="item">
                 <img :src="item" alt="">
              </el-carousel-item>
            </el-carousel>
          </div>
          <div class="download_link">
     
              <a v-for="button in buttons"   @click="button.version ? download_windows() : download_MacOS() " target="_blank" :key = button >
                  <span>{{button.name}}</span><i class="el-icon-download"></i>
              </a>
              <a  @click="register()" target="_blank"  >
                  <span class="register">账号注册引导</span>
              </a>
      </div>
    </div>

          <div class="download_text_a">
            <div class="abc">
              <ol>
                <li class="fw">应用场景：</li>
                <li class="li">1、文献搜索时实时在线解析当前搜索结果； （PubMed、Nature、中国知网、万方、维普等）；</li>
                <li class="li">2、个人积累文献的加载分析（前期下载的搜索结果、pdf格式文献、text格式文本）。</li>

                <li class="fw">服务功能：</li>
                <li class="li">1、语义关联矩阵展示阅读兴趣发散（文献内容深度揭示）；</li>
                <li class="li">2、树状层级展示文献内容热点聚焦（文献内容广度概览）</li>
                <li class="li">3、隐性知识链路发现，揭示文献中“意想不到”的知识链接路径。</li>
                <li class="li">4、任意概念触发新的主题视角、定位文献溯源，实现文本内容全超链导读。</li>
              </ol>
              <ol class="cjwt">
                <li class="fw">常见问题:</li>
                <li class="li">1、首次下载的用户可以试用《智慧导读》全功能（壹周）；</li>
                <li class="li">2、试用期后，注册用户仍可保留《智慧导读》部分功能；</li>
                <li class="li">3、申请成为plus会员，可继续使用《智慧导读》全功能服务。</li>
              <div class="sc">
                《{{productName}}》
                <a :href="manual_install" target="_blank" id="downloadLink_a"><strong>安装手册</strong></a>、
                <a :href="manual" target="_blank" id="downloadLink_b"><strong>用户使用手册</strong></a>、
                <a :href="manual_synopsis_pdf" target="_blank" id="downloadLink_c"><strong>软件简介(pdf)</strong></a>
           
              </div>
              </ol>
              
            </div>

  

         
        </div>
      <div class="menu">
      <div class="dropdown">
        <a href="https://beian.miit.gov.cn/">Copyright ©2024&nbsp;</a>
        <a href="https://www.banpo.com.cn/" class="shbp">上海半坡网络技术有限公司&nbsp;</a>
        <a href="https://beian.miit.gov.cn/">版权所有&nbsp;</a>
        <a href="https://beian.miit.gov.cn/">沪ICP备11024914号</a>
        <a href="https://www.banpo.com.cn/p-lianxiwomen.html" class="jszc">技术支持:</a>
        <p>021-55666722</p>
      </div>
    </div>
<!--    <div class="videos">
      <div class="videos_a">
      <span>安装 Windows 版本《{{ productName }}》</span>
      </div>
      <div class="tutorial"></div>
      <div class="videos_b">
        <span>安装MAC版本《{{ productName }}》</span>
      </div>
      <div class="tutorial"></div>
      <div class="videos_c">
        <span>安装Linux版本《{{ productName }}》</span>
      </div>
      <div class="tutorial"></div>
    </div>-->
<!--    <div class="help">
      <span class="help_text">使用期间需要帮助？</span> <br><br>
      <span class="help_text_a">访问我们的“技术支持”页获取有用的信息。</span>
    </div>-->
  </div>
</template>
<script>
import axios from 'axios'


// 产品名称
const productName = typeof window.PRODUCT_NAME !== 'undefined' ? window.PRODUCT_NAME : '智慧导读'
const baseUri = typeof window.API_BASE_URL !== 'undefined' ? window.API_BASE_URL : '/api'
const flavor = typeof window.PRODUCT_FLAVOR !== 'undefined' ? window.PRODUCT_FLAVOR : 'public'
const orgName = typeof window.ORG_NAME !== 'undefined' ? window.ORG_NAME: '上海半坡网络技术有限公司'

const title = '《' + productName + '》下载'

if (window.BELONG) {
  document.title = window.BELONG + title
} else {
  document.title = title
}

const manual = 'docs/用户手册.pdf'
const manual_install = 'docs/安装手册.pdf'
const manual_synopsis_pdf = 'docs/智慧导读服务简介.pdf'
const manual_synopsis_ppt = 'docs/智慧导读服务简介.pptx'


export default {
  data () {
    return {
      items:[require(".././img/pic/banner_1.png"),
             require(".././img/pic/banner_2.png"),
             require(".././img/pic/banner_3.png"),
             require(".././img/pic/banner_4.png"),
             require(".././img/pic/banner_5.png"),
             require(".././img/pic/banner_6.png"),
             require(".././img/pic/banner_7.png"),
             require(".././img/pic/banner_8.png"),
             require(".././img/pic/banner_9.png"),
             ],
      productName: productName,
      manual: manual,
      manual_install:manual_install,
      manual_synopsis_pdf:manual_synopsis_pdf,
      manual_synopsis_ppt:manual_synopsis_ppt,
      orgName: orgName,
      buttons: [],
      version:[]
    }
  },
  methods: {

    changeActive ($event) {
      $event.currentTarget.className = 'text_a'
    },
    removeActive ($event) {
      $event.currentTarget.className = ''
    },
    changeLogin ($event) {
      $event.currentTarget.className = 'login login_mouse'
    },
    removeLogin ($event) {
      $event.currentTarget.className = 'login'
    },
    changeDownLoad ($event) {
      $event.currentTarget.className = ' download_mouse'
    },
    removeDownLoad ($event) {
      $event.currentTarget.className = 'download_link'
    },
    download_MacOS(){
      this.$router.push('/MacOs')

    },
    download_windows(){
      this.$router.push('/Windows')

    },
    register(){
      this.$router.push('/Register')

    }
  },
  mounted () {
    const fileName_a = this.productName +"安装手册"
    const fileName_b = this.productName +"读者使用手册"
    const fileName_c = this.productName +"软件简介(pdf)"
    const fileName_d = this.productName +"软件简介(ppt)"
    var downloadLink_a = document.getElementById('downloadLink_a');
    var downloadLink_b = document.getElementById('downloadLink_b');
    var downloadLink_c = document.getElementById('downloadLink_c');
    // var downloadLink_d = document.getElementById('downloadLink_d');
     downloadLink_a.setAttribute('download', fileName_a);  
     downloadLink_b.setAttribute('download', fileName_b);  
     downloadLink_c.setAttribute('download', fileName_c);  
    //  downloadLink_d.setAttribute('download', fileName_d);  

    // var abc = document.documentElement.clientHeight;

    // var element = document.getElementById('app');
    // element.style.height = abc+'px';
    //     console.log(element);
    const now = new Date()
    // uri参数缓存
    const version = new Date().setHours(now.getHours(), now.getMinutes(), 0, 0).toString()
    axios.get(baseUri + '/versions/sorted?_=' + version + '&flavor=' + flavor).then((res) => {
      // 如果下载地址为外链
      const homeUri = window['download-origin'] || ''
      // 各平台下载链接
      const caches = {}
      // 按钮列表
      const buttons = []

      res.data.items.forEach(function (version) {
        const flavor = version.flavor?.name || 'default'
        const baseUri = homeUri + '/download/flavor/' + flavor + '/' + version.name

        // 各版本下的构建
        version.assets.forEach(function (asset) {
          const platform = asset.platform
          const filetype = asset.filetype

          switch (filetype) {
            case '.exe':
            case '.dmg': break
            default: return
          }

          if (caches.hasOwnProperty(platform)) {
            return
          }

          let name
          let version
          switch (platform) {
            case 'osx_64': name = '下载MacOS版本' ;version=false;  break
            case 'windows_64': name = '下载Windows版本';version=true; break
          }

          const button = { href: baseUri + '/' + platform + '/' + asset.name, name: name,version:version }

          caches[platform] = button
          buttons.push(button)
          console.log(buttons);
        })
      })

      this.buttons = buttons
    })
  }
}
</script>
<style lang="scss" scoped>
// #app{
//               background-image: url('.././img/bg.png');
//             background-repeat: no-repeat;
//             background-position: center;
//             background-size: cover;
//             margin: 0;
//             padding: 0;
//             width: 100%;
//             height: 100%;
//             overflow: auto;
// }
@media (max-width: 1000px){
.block{width: 100% !important;}
}
.top{
  padding-bottom:0.5vw ;
  padding-top:0.5vw ;
  margin-left:2vw ;
  
}
.block{
  width: 50vw;

  img{
  // height: 50%;
  width: 100%;
  }
}
 .text{
  display: flex;
   justify-content: space-evenly;
   flex-wrap: wrap;
  margin-top:1vw ;
  width: 100%;
 }
.menu{

  margin-top:4vw;
  display: flex;
  justify-content: center;

  padding: 0px 0 0 0;
  border-top:1px solid white;
  height: 40px;

    .dropdown{
      display: flex;
      align-items: center;
      list-style-type: none;
      
      font-size: 0.8vw;
      a{
        
        cursor:pointer;
      }
      p{
        margin-left:0.5vw ;
        color: white;
      }
      .shbp{
        color: white;
        font-weight: 700;
      }
      .jszc{
        margin-left:2vw ;
        color: white;
        font-weight: 700;
      }
    }
    .el-icon-user{
      font-size:150% ;
    }
}
.download{
  border-bottom:1px solid white;
  display: flex;
  justify-content: center;
  margin-top:1vw ;
   //border-bottom:1px solid rgba(121, 116, 116, 0.205);
  .download_text{

      display: flex;
      justify-content: center;

    // font-size: 36px;
    // text-align: center;
    //  position: relative;
    //   top: -80px;

    img{


      height:5vw ;
        // margin-left:450px ;
        // float: left;

    }
    .title{

      line-height: 5vw ;
      //  float: left;
      //   position: relative;
      //   top: 2rem;
        font-size: 2.2vw;
        color: white;
        float: left;
        font-family: "KaiTi";

    }
    .title_text{
      margin-top: 8%;
      //  position: absolute;
      //  top: 17%;
      //  left: 60%;
       font-size: 1.4rem;
        color: white;
    }


  }


}
  .download_link{
    position: relative;
    left: -5vw;
    display: flex;
    flex-direction:column;
    justify-content: center;
    font-size: 1vw;
    cursor: pointer;
    :hover {
      background: white;
      color: rgba(16, 78, 212, 0.733);
    }
   a{
      text-decoration: none;
      color: white;
      margin-top:30px ;
      padding: 0px 10px 15px 25px;
      border: 2px solid white;
      border-radius:100px

    }
    .el-icon-download{
      position: relative;
      top: 4px;
      font-size: 220%;
    }
    .register{
      padding-left:2.1vw ;
      position: relative;
      top: 7px;
    }
  }



a:visited{
  color:white;
}
a{
  text-decoration:none;
}


html{
  height: 100%;
}
    .sc{
      width: 40vw;
      margin-left:2.7vw ;
      margin-top:1vw ;
        a{
          color: white;
        }
      }
   .download_text_a{
    .abc{
      display: flex;

    }
      margin-left:10vw ;
      z-index: 2;
      font-size: 0.9vw;
      text-align: left;



      color: white;
      .welcome{

        color: white;
      }
      p:first-child {
        position: relative;
        left: -200px;
        color: white;

      }
      p{
        a{
          color: rgb(65, 65, 221);
        }
      }
   }
   ol{
    padding: 0;
    li{

    list-style: none;
    margin-top:4px ;
   }
   }

  .fw{
    color: white;
    font-weight: 700;
  }
  .li{
    padding: 0 0 0 40px;
  }
  .li_a{
    margin-top:15px ;
    padding: 0 0 0 165px;
    color: rgba(255, 0, 0, 0.76);
    font-weight: 900;
  }

.jj{
  display: flex;
  justify-content: center;
  color: white;
  font-size: 1vw;
}
.jjred{
  color: red;
}
</style>
