<template>
  <div class="about">
    <h1>下载Windows版本须知</h1>

    <div>
    

      <div class="windows_img">
        <img src=".././img/windows/1.png" alt="" class="img_4">
        <img src=".././img/windows/4.png" alt="" class="img_2">
        <img src=".././img/windows/2.png" alt="" class="img_4">
        <img src=".././img/windows/4.png" alt="" class="img_2">
        <img src=".././img/windows/3.png" alt="" class="img_1">
        <div class="img_text">安装过程进度条</div>
      </div>
      <div class="text_all">
        <div class="text">1、当您的计算机是Windows操作系统win 11或win 10版本时，需要您先同意运行安装文件，系统会自动安装，安装完成后自动打开客户端。</div>
        <div class="text">2、如果您的计算机操作是Windows前期版本，比如Win 7, 软件下载后，需要手工双击下载后的安装文件。</div>
        <div class="text">3、安装成功后，可以在屏幕桌面上找到《医学文献知识发现服务》软件启动图标
          <div class="text_a">双击打开软件。</div>
          <img src=".././img/windows/5.png" alt="" class="img_3">
        </div>
        
      </div>
   
    </div>
     
    
          <h1 class="download">
            <button><a href="/">返回下载页面</a></button>
            <button><a v-for="button in buttons" :href="button.href" :key = button>下载 Windows版本</a></button>
            
          </h1>
     
  </div>
</template>
<script>
import axios from 'axios'
// 产品名称
const productName = typeof window.PRODUCT_NAME !== 'undefined' ? window.PRODUCT_NAME : '智慧导读'
const baseUri = typeof window.API_BASE_URL !== 'undefined' ? window.API_BASE_URL : '/api'
const flavor = typeof window.PRODUCT_FLAVOR !== 'undefined' ? window.PRODUCT_FLAVOR : 'public'

const title = '《' + productName + '》下载'

if (window.BELONG) {
  document.title = window.BELONG + title
} else {
  document.title = title
}
export default {
  data(){
    return{
        buttons:[]
    }
  },

    mounted () {
    // var abc = document.documentElement.clientHeight;

    // var element = document.getElementById('app');
    // element.style.height = abc+'px';
    //     console.log(element);
    const now = new Date()
    // uri参数缓存
    const version = new Date().setHours(now.getHours(), now.getMinutes(), 0, 0).toString()
    axios.get(baseUri + '/versions/sorted?_=' + version + '&flavor=' + flavor).then((res) => {
      // 如果下载地址为外链
      const homeUri = window['download-origin'] || ''
      // 各平台下载链接
      const caches = {}
      // 按钮列表
      const buttons = []
 
      res.data.items.forEach(function (version) {
        const flavor = version.flavor?.name || 'default'
        const baseUri = homeUri + '/download/flavor/' + flavor + '/' + version.name

        // 各版本下的构建
        version.assets.forEach(function (asset) {
          const platform = asset.platform
          const filetype = asset.filetype

          switch (filetype) {

            case '.exe': break
            default: return
          }

          if (caches.hasOwnProperty(platform)) {
            return
          }

          let name
          let version
          switch (platform) {
            case 'windows_64': name = '下载Windows版本';version=true; break
          }

          const button = { href: baseUri + '/' + platform + '/' + asset.name, name: name,version:version }
          console.log(button);
          caches[platform] = button
          buttons.push(button)
        })
      })

      this.buttons = buttons
    })
  }
}
</script>
<style lang="scss" scoped>
.img_4{
  width:25vw;
}
.about{
  padding-top:3vw ;
}
.windows_img{
  margin-top: 1%;
img{
margin-left: 3%;
}
.img_1{
  width: 15vw;
  padding-bottom:5vw ;
}
.img_2{
  padding-bottom:8vw ;
  width: 5vw;
}
}
.img_3{
  position: relative;
  left:575px;
  top: -70px;
}
h1,button{
  margin: 0;
  display: flex;  
  justify-content: center;
}


  button{
    margin-top: 40px;
    width: 200px;
    font-size: 20px;
  }

.img_text{
  width: 150px;
  position: relative;
  left: 80vw;
  top: -5vw;
  font-size: 0.9vw;
}

.text_all{
margin-left: 25vw;
}
.text{
  margin-top:2vw ;
}
.text_a{
  position: relative;
  width: 150px;
  left: 650px;
  top: -20px;

}
.download{
  position: relative;
  top: -30px;
  display: flex;
  justify-content: space-evenly;
  a{
      text-decoration:none;
      line-height: 35px;
      color: black;
  }
}
</style>
