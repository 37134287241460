<template>
  <div class="about">

    <h1>下载Mac OS版本须知</h1>

    <div>
     

      <div class="windows_img">
        <img src=".././img/MacOS/1.png" alt="">
        <img src=".././img/MacOS/4.png" alt="" class="img_2">
        <img src=".././img/MacOS/2.png" alt="">
        <img src=".././img/MacOS/4.png" alt="" class="img_2">
        <img src=".././img/MacOS/5.png" alt="">
        <img src=".././img/MacOS/4.png" alt="" class="img_2">
        <img src=".././img/MacOS/6.png" alt="">


      </div>
      <div class="text_all">
             <div class="text">
                1、如果您的计算机操作是Mac OS操作系统，下载完成后，找到刚才下载的后缀名为.dmg的文件。双击该安装文件开始安装。
              </div>
              <div class="text">2、Mac OS会出现安装提示。请将左边安装软件图标拖入右边文件夹图标中，系统自动开始安装。</div>
              <div class="text">3、安装成功后，可以在应用程序找到《医学文献知识发现服务》软件启动图标</div>
              <img src=".././img/windows/5.png" alt="" class="img_3">
              <div class="text_a">双击打开软件。</div>
              
              
      </div>
    </div>
     
    
 
 
           <h1 class="download">
            <button><a href="/">返回下载页面</a></button>
            <button><a v-for="button in buttons" :href="button.href" :key = button>下载 MacOS版本</a></button>
            
          </h1>
 
     
  </div>
</template>
<script>
import axios from 'axios'
// 产品名称
const productName = typeof window.PRODUCT_NAME !== 'undefined' ? window.PRODUCT_NAME : '智慧导读'
const baseUri = typeof window.API_BASE_URL !== 'undefined' ? window.API_BASE_URL : '/api'
const flavor = typeof window.PRODUCT_FLAVOR !== 'undefined' ? window.PRODUCT_FLAVOR : 'public'

const title = '《' + productName + '》下载'

if (window.BELONG) {
  document.title = window.BELONG + title
} else {
  document.title = title
}
export default {
  data(){
    return{
        buttons:[]
    }
  },

    mounted () {
    // var abc = document.documentElement.clientHeight;

    // var element = document.getElementById('app');
    // element.style.height = abc+'px';
    //     console.log(element);
    const now = new Date()
    // uri参数缓存
    const version = new Date().setHours(now.getHours(), now.getMinutes(), 0, 0).toString()
    axios.get(baseUri + '/versions/sorted?_=' + version + '&flavor=' + flavor).then((res) => {
      // 如果下载地址为外链
      const homeUri = window['download-origin'] || ''
      // 各平台下载链接
      const caches = {}
      // 按钮列表
      const buttons = []
 
      res.data.items.forEach(function (version) {
        const flavor = version.flavor?.name || 'default'
        const baseUri = homeUri + '/download/flavor/' + flavor + '/' + version.name

        // 各版本下的构建
        version.assets.forEach(function (asset) {
          const platform = asset.platform
          const filetype = asset.filetype

          switch (filetype) {

            case '.dmg': break
            default: return
          }

          if (caches.hasOwnProperty(platform)) {
            return
          }

          let name
          let version
          switch (platform) {
            case 'osx_64': name = '下载MacOS版本' ;version=false;  break
          }

          const button = { href: baseUri + '/' + platform + '/' + asset.name, name: name,version:version }
          console.log(button);
          caches[platform] = button
          buttons.push(button)
        })
      })

      this.buttons = buttons
    })
  }
}
</script>
<style lang="scss" scoped>
.about{
  padding-top:3vw ;
}
.windows_img{
  margin-top: 3%;
  margin-left: 80px;
  
img{
width: 20%;
margin-left: 1%;
min-width:none;
}
.img_1{
  padding-bottom:100px ;
}
.img_2{
  padding-bottom:6% ;
  width: 3%;
}
}
.img_3{
  position: relative;
  left: 560px;
  top: -70px;
}
h1,button{
  margin: 0;
  display: flex;  
  justify-content: center;
  
}


  button{

    width: 200px;
    height: 40px;
    font-size: 20px;
  }

.img_text{
  position: absolute;
  left: 655px;
  top: 450px;
}
.text{
  margin-top:50px ;
}
.text_a{
  position: relative;
  width: 150px;
  left: 645px;
  top: -115px;
}
.text_all{
  margin-left:30vw ;
}
.download{
  display: flex;
  justify-content: space-evenly;
  a{
      text-decoration:none;
      line-height: 35px;
      color: black;
  }
}
</style>
